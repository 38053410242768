import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { AppConst } from "../../AppConst";
import { cloneDeep } from 'lodash';
import { PerilFactorComponent } from "../models/pricing/rating-factor-popup/peril-factor/peril-factor.component";
import { ApiResult, ApiResultData } from "../dto/api-result";
import { DropdownMaster } from "../dto/dropdown-master";
import { PerilListDto } from "../dto/peril-list.model";
import { ExternalCoverageInfo, OrdinanceOrLawCoverage, OrdinanceOrLawItem } from "../dto/coverage";
import { of } from 'rxjs';
import { GeneralLiability } from "../dto/general-liability";
import { Response } from "../dto/response";
import { PropertyEnhancements } from "../dto/property-enhancements";

@Injectable({
  providedIn: "root",

})
export class CommonService {
  http: HttpClient;
  quoteId: any;

  pdf: PerilFactorComponent;
  constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
  }
  // private submissionData = new BehaviorSubject({});
  private buildingMasterData = new BehaviorSubject<any>({});
  private riskEvaluationMasterData = new BehaviorSubject<any>({});
  private masterData = new BehaviorSubject<any>({});
  private policyStatusData = new BehaviorSubject('');
  dynamicStatusData = new BehaviorSubject({});
  currentPolicyStatus = this.policyStatusData.asObservable();
  currentBMData = this.buildingMasterData.asObservable();
  currentReMData = this.riskEvaluationMasterData.asObservable();
  // currentSubmissionData = this.submissionData.asObservable();
  allmasterData=this.masterData.asObservable();
  statusBarData = this.dynamicStatusData.asObservable();

  setStatusBarData(data: any) {
    this.dynamicStatusData.next(data);
  }
  setBuildingMasterData(data: any) {
    this.buildingMasterData.next(data);
  }
  setPropertyMasterData(data: any) {
    this.masterData.next(data);
  }
  setPolicyStatusData(data: string) {
    this.policyStatusData.next(data);
  }
  // setSubmissionData(data: any) {
  //   this.submissionData.next(data);
  // }

  setRiskEvaluationMasterData(data: any) {
    this.riskEvaluationMasterData.next(data);
  }

  getMaster() {
    return this.buildingMasterData;
  }

  getRiskEvalMaster() {
    return this.riskEvaluationMasterData;
  }
  getPropertyMasterData() {
    return this.allmasterData;
  }
  getPolicyStatusByQuoteId(quoteId: number) {
    let url_ = `${AppConst.apiUrl}Policy/GetPolicyStatusByQuoteId?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  GetPolicyInfoByQuoteId(quoteId: number) {
    let url_ = `${AppConst.apiUrl}Policy/GetPolicyInfoByQuoteId?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);

  }
  getAddressByZipCode(zipCode) {
    let url_ =
      `${AppConst.apiUrl}Common/GetAddressByZipCode?zipCode=` + zipCode;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getPayPlan() {
    let url_ = `${AppConst.apiUrl}Common/GetPayPlan`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getTransactionData(quoteId) {
    let url_ = `${AppConst.apiUrl}Common/GetTransactionType?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  runRenew(data): Observable<any> {
    var _data = cloneDeep(data);
    //if (data && (data.EffectiveDate != null && data.EffectiveDate != undefined)) {
    //  _data.EffectiveDate = data.EffectiveDate.toLocaleDateString('en-us');
    //}
    let url_ = `${AppConst.apiUrl}Policy/RunRenew`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, _data);
  }
  runEndorsement(data): Observable<any> {
    var _data = cloneDeep(data);
    if (data && (data.EffectiveDate != null && data.EffectiveDate != undefined)) {
      _data.EffectiveDate = data.EffectiveDate.toLocaleDateString('en-us');
    }
    let url_ = `${AppConst.apiUrl}Policy/RunEndorsement`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, _data);
  }

  getPropertyPolicyInfo(quoteId, policyInfoType?: Number) {
    let url_ = `${AppConst.apiUrl}Policy/GetPropertyPolicyInfo?quoteId=` + quoteId + '&type=' + policyInfoType;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getAxonRaterInfo(quoteId) {
    let url_ = `${AppConst.apiUrl}Policy/GetAxonRater?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  getExcessRaterInfo(quoteId) {
    let url_ = `${AppConst.apiUrl}Policy/GetExcessRater?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getGLRaterInfo(quoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetGeneralLiabilityPremium?QuoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  GetExtCoveragePremium(quoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetExtCoveragePremium?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getAxonPropLocationInfo(quoteId) {
    let url_ = `${AppConst.apiUrl}Policy/GetAxonRaterPropLocation?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getAxonPMLInfo(quoteId) {
    let url_ = `${AppConst.apiUrl}Policy/GetAxonRaterPML?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getPropertyPolicyInfoPrintView(quoteId) {
    let url_ = `${AppConst.apiUrl}Policy/GetPropertyPolicyInfoPrintView?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  addUpdatePeril(data, quoteId): Observable<any> {
    let url_ = `${AppConst.apiUrl}Policy/AddUpdatePeril?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }
  addUpdatePricingInfo(data, quoteId): Observable<any> {
    let url_ = `${AppConst.apiUrl}Policy/AddUpdatePricingInfo?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }
  addUpdatePremium(data): Observable<any> {
    let url_ = `${AppConst.apiUrl}Rater/AddUpdatePremium`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }
  GetPremiumRater(quoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetPremiumRater?QuoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  calculatePricing(data, quoteId): Observable<any> {
    let url_ = `${AppConst.apiUrl}Policy/CalculatePricing?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }
  getDashBoardProgramList(): Observable<any> {
    let url_ = `${AppConst.apiUrl}Common/GetDashBoardProgramList`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  getEndorsementForms(quoteId: any) {
    let url_ = `${AppConst.apiUrl}Common/GetEndorsementForms?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  saveEndorsementForms(data: any) {
    let url_ = `${AppConst.apiUrl}Common/SaveEndorsementForms`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }

  getFormDocument(quoteId, FormId) {
    let url_ = `${AppConst.apiUrl}Common/GetFormDocument?quoteId=` + quoteId + "&FormId=" + FormId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  // genTxtxml(quoteId,pagecode){
  //   let url_ = `${AppConst.apiUrl}Common/GenXml?quoteId=` + quoteId  + "&pagecode=" + pagecode;
  //   url_ = url_.replace(/[?&]$/, "");
  //   return this.http.get<any>(url_);
  // }

  docViewAsPDF(data) {
    let url_ = `${AppConst.apiUrl}DataMaintenance/DocViewAsPDF`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }
  AddUpdateExtCoverage(data: PropertyEnhancements) {
    let url_ = `${AppConst.apiUrl}Rater/AddUpdateRaterCoverages`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<ApiResultData<Response>>(url_, data);
  }

  getOrdinanceOrLawCoverageList(quoteId: number) {
    let url_ = `${AppConst.apiUrl}Rater/GetOrdinanceOrLawCoverage/` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<ApiResultData<OrdinanceOrLawItem[]>>(url_);
  }

  getRiskEvaluationList(quoteId) {
    let url_ = `${AppConst.apiUrl}Location/GetRiskEvaluationList?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getDropDownMaster(quoteId, stateCode) {
    let url_ = `${AppConst.apiUrl}Common/GetAllDropdownList?quoteId=` + quoteId + "&stateCode=" + stateCode;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<ApiResultData<DropdownMaster>>(url_);
  }
  getExtCoverageEntity() {
    let url_ = `${AppConst.apiUrl}Rater/GetExtCoverageEntity`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getExtCoverage(quoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetExtCoverageInfo?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<ApiResultData<ExternalCoverageInfo>>(url_);
  }

  getPerilDataByQuoteId(quoteId) {
    let url_ = `${AppConst.apiUrl}Policy/GetCoverageDataByQuoteId?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<ApiResultData<PerilListDto>>(url_).toPromise();
  }
  getDescriptionOfOperationsBySicCodeId(sicCodeId) {
    let url_ = `${AppConst.apiUrl}Rater/GetDescriptionOfOperationsBySicCodeId?sicCodeId=` + sicCodeId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getDefaultData(quoteid) {
    let url_ = `${AppConst.apiUrl}Policy/GetPerilDefaultData?quoteid=` + quoteid;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  removeLocationperil(LocationId: any, QuoteId: any) {
    let url_ = `${AppConst.apiUrl}Policy/DeleteLocationPerilByLocationId?LocationId=` + LocationId + "&Quoteid=" + QuoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  getGeneralLiability(quoteid) {
    let url_ = `${AppConst.apiUrl}Rater/GetGeneralLiability?quoteid=` + quoteid;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<ApiResultData<GeneralLiability>>(url_);
  }
  saveGeneralLiability(data: GeneralLiability) {
    let url_ = `${AppConst.apiUrl}Rater/SaveGeneralLiability`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<ApiResultData<Response>>(url_, data);
  }
  getGeneralLiabilityMasterData(quoteid) {
    let url_ = `${AppConst.apiUrl}Rater/GetGeneralLiabilityMasterData?QuoteId=` + quoteid;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  // EvaluateReferral(QuoteId,Pagecode){
  //   let url_ = `${AppConst.apiUrl}Referral/EvaluateAndAddReferrals?quoteId=` + QuoteId + "&pageCode=" + Pagecode;
  //   url_ = url_.replace(/[?&]$/, "");
  //   return this.http.get<any>(url_);
  // }
  getDnBServiceData(quoteid) {
    let url_ = `${AppConst.apiUrl}Rater/GetDnBServiceData?quoteid=` + quoteid;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getDunsServiceData(Duns, QuoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetDnBDunsData?Duns=` + Duns + "&QuoteId=" + QuoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getNetRateProcess(data): Observable<any> {
    let url_ = `${AppConst.apiUrl}Rater/ProcessNetRate`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }
  getnetRateServiceLogByQuoteId(quoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetNetRateServiceLog?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getGlCoveragePremiumByQuoteId(quoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetGLCoveragePremiumByQuoteId?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  getDNBServiceLogByQuoteId(quoteId) {
    let url_ = `${AppConst.apiUrl}Rater/GetDNBServiceLog?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  getAxonDocument(quoteid: Number, docCode: string) {
    let url_ = `${AppConst.apiUrl}GenerateDocument/GetAxonDocument?docCode=` + docCode;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get(url_);
  }

  getFlagForReferrals(QuoteId: number, LocationId: number, BuildingId: number, FloodZone: any, ActionType: any, PageCode: any, ISOPublicClass: any, ISOConstClass: any, Floors: any, SicCode: any) {
    let url_ = `${AppConst.apiUrl}Referral/GetFlagForReferrals?QuoteId=` + QuoteId + "&LocationId=" + LocationId + "&BuildingId=" + BuildingId + "&FloodZone=" + FloodZone + "&ActionType=" + ActionType + "&PageCode=" + PageCode + "&ISOPublicClass=" + ISOPublicClass + "&ISOConstClass=" + ISOConstClass + "&Floors=" + Floors + "&SicCode=" + SicCode;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get(url_);
  }

  GetZipSpecificReferralFlag(QuoteId: number, LocationId: number, BuildingId: number, ActionType: any, PageCode: any, BPPValue: number, BRPValue: number, BIValue: number) {
    let url_ = `${AppConst.apiUrl}Referral/GetZipSpecificReferralFlag?QuoteId=` + QuoteId + "&LocationId=" + LocationId + "&BuildingId=" + BuildingId + "&ActionType=" + ActionType + "&PageCode=" + PageCode + "&BPPValue=" + BPPValue + "&BRPValue=" + BRPValue + "&BIValue=" + BIValue;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get(url_);
  }

  getPerilScheduledViewLocation(quoteId) {
    let url_ = `${AppConst.apiUrl}Policy/GetPerilScheduleViewByQuoteId?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  addUpdatePerilScheduleView(data, quoteId): Observable<any> {
    let url_ = `${AppConst.apiUrl}Policy/AddUpdatePerilScheduleView?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, data);
  }

  getAllLocationStateFactorDiff(quoteid: Number) {
    let url_ = `${AppConst.apiUrl}Policy/GetAllLocationStateFactorDiff?quoteId=${quoteid}`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  getCrimeAndInLandMarineData(quoteId: Number) {
    let url_ = `${AppConst.apiUrl}Rater/GetCrimeAndInLandMarineData?quoteId=` + quoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  //getPerilLocationAdress(quoteid: Number, buildingId:number) {
  //  let url_ = `${AppConst.apiUrl}Policy/GetPerilLocationAdress?quoteId=${quoteid}&BuildingId=${buildingId}`;
  //  url_ = url_.replace(/[?&]$/, "");
  //  return this.http.get<any>(url_);
  //}
}

