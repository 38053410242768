
<section class="search-page-section">
  <span class="login-logo">
    Axon
  </span>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="logout-message">
          <h2> You have successfully logged out from AxonU portal</h2>
          <h2> Thanks for using AxonU Portal!! </h2>
        </div>        
      </div>
    
    </div>
    <div class="row" *ngIf="isShowLink">
      <div class="col-md-12">  
      <div class="logout-message">
        <a class='btn currency-gap' (click)="redirectToLogin()"><u>click here</u></a>  <h5>to redirect on Login.</h5>
      </div>
    </div>
    </div>
  </div>
</section>
