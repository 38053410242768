import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RootRoutingModule } from './root-routing.module';
import { RootComponent } from 'src/root.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';

import { NgSelectModule } from '@ng-select/ng-select';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './shared/service-proxy/auth-guard/auth';
import { NotifyService } from './shared/service-proxy/toastr.service';
import { CommonService } from './shared/service-proxy/commom.service';
import { InterceptorService } from './shared/service-proxy/interceptor/interceptor.service';
import { AuthService } from './shared/service-proxy/auth.service';
//import { GridModule } from '@progress/kendo-angular-grid';
import { ControlredirectComponent } from './controlredirect/controlredirect.component';
import { AxonulogoutComponent } from './axonulogout/axonulogout.component';
import { AppConst } from './AppConst';



@NgModule({
  declarations: [
    RootComponent,
    PageNotFoundComponent,
    ControlredirectComponent,
    AxonulogoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RootRoutingModule,
    ReactiveFormsModule, NgSelectModule,
    NgxSpinnerModule, MatDialogModule,// MatButtonModule, MatButtonToggleModule, MatTooltipModule,
    ToastrModule.forRoot({ preventDuplicates: true, positionClass: 'toast-bottom-right' }), //GridModule,
  ],
  providers: [NotifyService, CommonService, AuthService,{
    provide: APP_INITIALIZER,
    multi: true,
    deps: [AppConst],
    useFactory: (appConfigService: AppConst) => {
      return () => {
        return appConfigService.loadAppSettings();
      };
    }
  },
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [RootComponent]
 
  
})
export class RootModule { }
