import { Injectable } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpResponse, HttpEvent, HttpHandler, HttpInterceptor } from '@angular/common/http';
import { AuthService } from '../auth.service';

import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotifyService } from '../toastr.service';
import { AppConst } from '../../../AppConst';
@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private authService: AuthService, private notify: NotifyService, private router: Router, private spinner: NgxSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var securityKey = AppConst.securityKey; 
    var token = JSON.parse(localStorage.getItem(`T${securityKey}`));
    let newHeaders = req.headers; 
    this.spinner.show();
    /*!newHeaders["lazyUpdate"]*/
    if ((AppConst.isPassTokenInUrl ? localStorage.getItem(`T${AppConst.securityKey}`) : token)) {
      newHeaders = newHeaders.append('Authorization', `Bearer ${token.AccessToken}`);
    }

    /*Fix caching issue: Praveen : 22-Nov-22 - starts*/
    newHeaders = newHeaders.append('Cache-Control', 'no-cache');
    newHeaders = newHeaders.append('Pragma', 'no-cache');
    /*Fix caching issue: Praveen : 22-Nov-22 - ends*/

    const authReq = req.clone({ headers: newHeaders });
    return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.spinner.hide();
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        this.spinner.hide();
        if (err.status !== 401) {
          this.notify.error("Server is not responding", "Error");
          return;
        }
        this.authService.logout();
      }
    }));

  }
}
