import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ControlredirectComponent } from './controlredirect/controlredirect.component';
import { AxonulogoutComponent } from './axonulogout/axonulogout.component';
import { AxonUIFrameComponent } from './app/axon-uiframe/axon-uiframe.component';
import { AxonuloginComponent } from './axonulogin/axonulogin.component';
const routes: Routes = [

  { path: '', redirectTo: '/axonlogin', pathMatch: 'full' },
  {
    path: 'axonlogin', component: AxonuloginComponent
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login', component: ControlredirectComponent
  },
  //{
  //  path: 'login/:controlId/:userId', component: ControlredirectComponent
  //},
  //{
  //  path: 'login/:controlId/:userId/:logout', component: ControlredirectComponent
  //},
  {
    path: 'login/:controlId/:userGuid', component: ControlredirectComponent
  },
  {
    path: 'login/:controlId/:userGuid/:accessControl', component: ControlredirectComponent
  },
  { path: '', redirectTo: '/logout', pathMatch: 'full' },
  {
    path: 'logout', component: AxonulogoutComponent
  },
  {
    path: 'app' ,
    loadChildren: () => import('../src/app/app.module')
      .then(m => m.AppModule),
    data: { preload: true } 
  },
  { path: '**', component: PageNotFoundComponent }

];


@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RootRoutingModule {
  ngOnInit(): void {

    throw new Error("Method not implemented.");
  }
}

