
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NotifyService } from '../shared/service-proxy/toastr.service';
import { ResponseCode } from '../shared/dto/response-code';
import { AuthService } from '../shared/service-proxy/auth.service';

import { Guid } from "guid-typescript";
import { AppConst } from '../AppConst';

@Component({
  selector: 'app-axonulogin',
  templateUrl: './axonulogin.component.html',
  styleUrls: ['./axonulogin.component.css']
})

export class AxonuloginComponent implements OnInit {
  //public UserName: any;
  //public UserPass: any;
  loginInfo: any = {};
  role: Array<any> = [];
  public password: any;
  public show = false;
  public UserGuid: any;
  login: Login = new Login();
  @ViewChild("loginForm", { read: NgForm }) loginForm: NgForm;

  constructor(private router: Router, private route: ActivatedRoute, private notify: NotifyService, private authService: AuthService) { }

  ngOnInit(): void {
    this.password = 'password';
    if (localStorage.getItem(`T${AppConst.securityKey}`)) {
      localStorage.removeItem(`T${AppConst.securityKey}`);
    }
    if (localStorage.getItem(`CU${AppConst.securityKey}`)) {
      localStorage.removeItem(`CU${AppConst.securityKey}`);
    }
    if (localStorage.getItem(`ControlNumber`)) {
      localStorage.removeItem(`ControlNumber`);
    }
    if (localStorage.getItem(`AccessControl`)) {
      localStorage.removeItem(`AccessControl`);
    }

  }

  onClickLogin(login: any) {

    if ((login.UserName != undefined && login.UserName != null && login.UserName != '') && (login.UserPass != undefined && login.UserPass != null && login.UserPass != '')) {
      this.validateUserDetails(login.UserName, login.UserPass);
    }
    else {
      this.notify.error('User Name or Password should not be blank', "Error:");
    }

  }

  validateUserDetails(userName: any, UserPass: any) {

    this.authService.verifyUserDetails(userName, UserPass).subscribe((res: any) => {
      if (res != null && res.Status == ResponseCode.Ok) {
        this.UserGuid = res.Data[0].UserGuid;
        var guid = Guid.parse(this.UserGuid);

        this.role = res.Data;
        if (this.role != null && this.role.length == 1) {
          this.loginInfo.role = this.role[0];
          this.userLogin(this.loginInfo, guid);
        }
        else {
          this.notify.info("Role specified more than one.", "Info");
        }

      }
      else {
        this.notify.error(res.Message, "Error");
        this.router.navigate(['axonlogin']);
      }
    },
      error => {
        this.notify.info("ERR_CONNECTION_REFUSED.", "Info");

      });
  }


  userLogin(data, guid: any) {
    if (data.role == undefined && data.roleId > 0) {
      data.role = this.role.filter(a => a.RoleId == data.RoleId)[0];
    }
    this.authService.login(data).subscribe((response: any) => { // need to send also username & passsword(string)
      localStorage.removeItem(`T${AppConst.securityKey}`);
      if (response.Data && response.Data["AccessToken"]) {
        this.role = [];
        this.loginInfo.role = null;

        localStorage.setItem(`T${AppConst.securityKey}`, JSON.stringify(response.Data));
        setTimeout(() => {
          localStorage.setItem(`T${AppConst.securityKey}`, JSON.stringify(response.Data));
        }, 1000);

        this.getUserEncryptedGuid(guid);
      }
      else {
        this.notify.error(response.Message, "Error");
      }
    },
      error => {
        this.notify.error("ERR_CONNECTION_REFUSED.", "Error");
      });

  }

  getUserEncryptedGuid(guid: any) {
    this.authService.getUserEncryptedGuid(guid).subscribe((res: any) => {
      if (res != null && res.Status == ResponseCode.Ok) {
        this.notify.success('Successfully logged in.', 'Success');
        var encryptUserGuid = res?.Data?.Data;
        this.redirectToLogin(encryptUserGuid);
      }
      else {
        this.notify.error(res.Message, "Error");
        this.router.navigate(['axonlogin']);
      }
    },
      error => {
        this.notify.info("ERR_CONNECTION_REFUSED.", "Info");
      });
  }

  redirectToLogin(encryptUserGuid: any) {
    this.router.navigate(['/login', Number(0), encryptUserGuid, 'P']).then(() => {
    });
  }


  showValidationMessage() {
    var field = [{
      UserName: "User Name",
      UserPass: "User Password",
    }];

    var isValidate = false;
    if (this.loginForm && this.loginForm.controls) {
      for (var control in this.loginForm.controls) {
        if (this.loginForm.controls[control].errors && isValidate == false) {
          isValidate = true;
          this.notify.error('User Name or Password should not be blank', "Error:");
        }
      }
    }
  }

  showPassword() {

    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }

  }

}

export class Login {
  UserName: string | undefined | null;
  UserPass: string | undefined | null;
  constructor() {
    this.UserName = "";
    this.UserPass = "";
  }
}







