import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-axonulogout',
  templateUrl: './axonulogout.component.html',
  styleUrls: ['./axonulogout.component.css']
})
export class AxonulogoutComponent implements OnInit {
  public AccessControl: any;
  isShowLink: boolean = true;

  constructor( private router: Router) { }

  ngOnInit(): void {
    var access = localStorage.getItem(`AccessControl`);
    if (access != null || access != undefined) {
      this.AccessControl = JSON.parse(access);
      if (this.AccessControl == 'T')
        this.isShowLink = false;
    }

  }

  redirectToLogin(){
    //this.router.navigate(["/login/0/0/out"]);
    this.router.navigate(["/axonlogin"]);
  }
}
