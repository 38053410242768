<section class="search-page-section">
  <span class="login-logo">
    Axon
  </span>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="login-box control-idsearch-page">
          <form #loginForm="ngForm" (ngSubmit)="loginForm.valid && onClickLogin(login)" novalidate>
            <div class="form-group mb-4">
              <h4>Login</h4>
            </div>
            <div class="form-group mb-3">
              <label>Enter Your User Name<strong class="required-str">*</strong></label>
              <input type="text" class="form-control" name="UserName" id="UserName" #UserName ="ngModel" [(ngModel)]="login.UserName"
                     placeholder="Enter User Name" (keydown)="$event.keyCode != 32 ? $event:$event.preventDefault()"
                     [ngClass]="{'k-required':UserName.invalid && loginForm.submitted }" required />
            </div>
            <div class="form-group mb-3">
              <label>Enter Your Password<strong class="required-str">*</strong></label>
              <input [type]="password" class="form-control" name="UserPass" id="UserPass" #UserPass="ngModel" [(ngModel)]="login.UserPass" placeholder="Enter User Password"
                     (keydown)="$event.keyCode != 32 ? $event:$event.preventDefault()" [ngClass]="{'k-required':UserPass.invalid && loginForm.submitted}" required />
              <button type="button" class="eye-icon" (click)="showPassword()">
                <span *ngIf="!show"><i class="fa fa-eye" aria-hidden="true"></i></span>
                <span *ngIf="show"><i class="fa fa-eye-slash" aria-hidden="true"></i></span>
              </button>
            </div>
            <div class="mt-4 mb-2">
              <button class="btn btn-primary" type="submit" (click)="showValidationMessage();"> Login </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
