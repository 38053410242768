import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-root",
  template: `
    <ngx-spinner size="large" type="ball-clip-rotate" ></ngx-spinner>
    <router-outlet></router-outlet>
  `,
  styles: [],
})
export class RootComponent implements OnInit {
  ngOnInit(): void {}
}
