import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NotifyService } from '../shared/service-proxy/toastr.service';
import { SubmissionService } from '../shared/service-proxy/submission.service';
import { ResponseCode } from '../shared/dto/response-code';
import { AuthService } from '../shared/service-proxy/auth.service';

import { Guid } from "guid-typescript";
import { AppConst } from '../AppConst';

@Component({
  selector: 'app-controlredirect',
  templateUrl: './controlredirect.component.html',
  styleUrls: ['./controlredirect.component.css']
})
export class ControlredirectComponent implements OnInit {
  public ControlNumber: any;
  public UserId: any;
  //public logout: any;
  public UserGUID: any;
  public AccessControl: any;
  public TblUserDetail: any;
  loginInfo: any = {};
  role: Array<any> = [];
  UGuidEncryptInfo: UserGuidEncrypt = new UserGuidEncrypt();
  constructor(private router: Router, private route: ActivatedRoute, private notify: NotifyService, private _submissionService: SubmissionService, private authService: AuthService) { }

  ngOnInit(): void {

    //if (localStorage.getItem(`ControlNumber`)) {
    //  localStorage.removeItem(`ControlNumber`);
    //}
    //if (localStorage.getItem(`AccessControl`)) {
    //  localStorage.removeItem(`AccessControl`);
    //}
   // this.logout = this.route.snapshot.paramMap.get("logout")    
    //this.UserId = Number(this.route.snapshot.paramMap.get("userId"));
    this.UserGUID = this.route.snapshot.paramMap.get("userGuid");
    this.AccessControl = this.route.snapshot.paramMap.get("accessControl");
    this.ControlNumber = Number(this.route.snapshot.paramMap.get("controlId"));

    if (this.AccessControl == null || this.AccessControl == undefined)
      this.AccessControl = 'T';

    if (this.UserGUID != null && this.UserGUID != undefined && this.AccessControl != null && this.AccessControl != undefined) {

      var iSGuid = Guid.isGuid(this.UserGUID);
      if (iSGuid && this.AccessControl == 'T') {
        this.getTblUserDetailByGuid(this.UserGUID);// called API for UserID.

      }
      else if ((!iSGuid && this.UserGUID != null) && this.AccessControl == 'T') {
        this.UserId = this.UserGUID; //here it is user id comes from Tool.
        if (this.isNumber(this.UserId))
          this.onClickSubmit();
        else {
          this.notify.error("Query string manipulated.", "Error :");
          this.router.navigate(['axonlogin']);
        }

      }
      else if (this.AccessControl == 'P') {
        let promise = new Promise((resolve, reject) => {
          this.UGuidEncryptInfo = new UserGuidEncrypt();
          this.UGuidEncryptInfo.UserGuidEncrypt = this.UserGUID;

          this.authService.getUserDecryptedGuid(this.UGuidEncryptInfo).toPromise().then(res => {
            if (res != null && res.Status == ResponseCode.Ok) {
              var decryptUserGuid = res?.Data?.Data;
              if (Guid.isGuid(decryptUserGuid)) {
                this.getTblUserDetailByGuid(decryptUserGuid); // called API for UserID.
              }
              else
                this.notify.error("User Guid is not valid", "Error :");
            }
            else {
              this.notify.error(res.Message, "Error :");
              this.router.navigate(['axonlogin']);
            }
          },
            error => {
              this.notify.info("ERR_CONNECTION_REFUSED.", "Info");

            });

        });
      }
      else {
        this.notify.error("Query string manipulated.", "Error :");
        this.router.navigate(['axonlogin']);
      }

    }
    else {
      this.router.navigate(['axonlogin']);
    }
                    
  }

  onClickSubmit() {
    //console.log(this.UserId);
      if (this.UserId != null && this.UserId != undefined && this.UserId > 0) {
          if (this.ControlNumber == undefined || this.ControlNumber == Number(0)) {
            //this.notify.error('Control Number should not be blank or 0', "Error :");
          }
          else {
            //this.UserId = 156; 
            this.validateUserRole(this.UserId); // check User Authentication.
          }
      }
      else {
        this.notify.error("User not found", "Error :");
        this.router.navigate(['axonlogin']);
      }
    
  }

  setControlNo() {
    localStorage.setItem(`ControlNumber`, JSON.stringify(this.ControlNumber));
    setTimeout(() => {
      localStorage.setItem(`ControlNumber`, JSON.stringify(this.ControlNumber));
    }, 1000);

    localStorage.setItem(`AccessControl`, JSON.stringify(this.AccessControl));
    setTimeout(() => {
      localStorage.setItem(`AccessControl`, JSON.stringify(this.AccessControl));
    }, 1000);

  }

  validateUserRole(data) {
    if (this.role && this.role.length == 0) {
      this.role = [];
      this.loginInfo.role = null;
      this.authService.getUserRole(data).subscribe((res: any) => {
        if (res != null && res.Status == ResponseCode.Ok) {
          this.role = res.Data;
          if (this.role.length == 1) {
            this.loginInfo.role = this.role[0];
            this.login(this.loginInfo);
          }
        }
        else {
          this.notify.error(res.Message, "Error :");
          //this.router.navigate(['AxonULogin', 0, 0]);
          this.router.navigate(['logout']);
        }
      },
        error => {
          this.notify.info("ERR_CONNECTION_REFUSED.", "Info");

        });
    }
    else {
      if (data.roleId > 0 && (this.role && this.role.length > 0))
        this.loginInfo.role = this.role.filter(x => x.roleId == this.loginInfo.roleId)[0]
        this.login(data);
    }

  }

  login(data) {
    if (data.role == undefined && data.roleId > 0) {
      data.role = this.role.filter(a => a.RoleId == data.roleId)[0];
    }

    this.authService.login(data).subscribe((response: any) => { // need to send also username & passsword(string)
      localStorage.removeItem(`T${AppConst.securityKey}`);
      if (response.Data && response.Data["AccessToken"]) {
        this.role = [];
        this.loginInfo.role = null;
        localStorage.setItem(`T${AppConst.securityKey}`, JSON.stringify(response.Data));
        setTimeout(() => {
          localStorage.setItem(`T${AppConst.securityKey}`, JSON.stringify(response.Data));
        }, 1000);       

        this.redirectToPortal();
        this.authService.setUserDetails();
      }
      else {
        this.notify.error(response.Message, "Error :");
      }
    },
      error => {
        this.notify.error("ERR_CONNECTION_REFUSED.", "Error :");
      });

    //this.notify.success('Successfully logged in.', 'Success');
    //this.redirectToPortal();
  }



  redirectToPortal() {

    if (localStorage.getItem(`ControlNumber`)) {
      localStorage.removeItem(`ControlNumber`);
    }
    //console.log(this.ControlNumber);
    this._submissionService.getQuoteId(this.ControlNumber).subscribe((response: any) => {
      if (response && response.Status == ResponseCode.Ok) {
        var QuoteId = response.Data.QuoteId;

        if (this.AccessControl == 'T')
          this.notify.success('Successfully logged in.', 'Success');
        else if (this.AccessControl == 'P')
          this.notify.success('Successfully fetched control info.', 'Success');

        this.setControlNo();
        this.router.navigate(['/app/submission', Number(QuoteId)]).then(() => {
          window.location.reload();
        });

      }
      else {
        if (response.Success && response.Data != undefined) {
          if (response.Data.QuoteId == -9999)
              this.notify.info(response.Message, "Info");
              this.authService.logout();
        }
        else if (response.Success == false) {
          this.notify.error(response.Message, "Error :");
        }
        else {
          if (this.AccessControl == 'T') {
            this.notify.success('Successfully logged in.', 'Success');

            this.setControlNo();
            this.router.navigate(['/app/submission', Number(0)]).then(() => {
              window.location.reload();
            });
          }
          else if (this.AccessControl == 'P') {
            this.notify.error('Control number is not found.', "Error :");
          }
                     
        }

      }
    },
      error => {
        this.notify.info("ERR_CONNECTION_REFUSED.", "Info");
      });;

  }

  getTblUserDetailByGuid(guid: any) {
    let promise = new Promise((resolve, reject) => {
      this.authService.getTblUserDetailByGuid(guid).toPromise().then(res => {
        if (res.Success && res.Data != null) { 
          this.TblUserDetail = res.Data;
          this.UserId = this.TblUserDetail.UserID;

         // if (this.logout != 'out' && this.logout == null && this.logout == undefined) {
            this.onClickSubmit();
         // }

        }
        else {
          this.notify.error(res.Message, "Error :");
        }
      });
    });
    
  }

    isNumber(str: string): boolean {
    if (typeof str !== 'string') {
      return false;
    }

    if (str.trim() === '') {
      return false;
    }

    return !Number.isNaN(Number(str));
  }

}

export class UserGuidEncrypt {
  UserGuidEncrypt: string | undefined | null;
  constructor() {
    this.UserGuidEncrypt = "";
  }
}
