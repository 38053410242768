<section class="search-page-section">
  <span class="login-logo">
    Axon
  </span>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="control-idsearch-page">
          <h3>Enter Your Control Id<strong class="required-str">*</strong></h3>
          <input type="number" class="form-control" name="QuoteId" id="ControlNum" [(ngModel)]="ControlNumber" placeholder="Enter Control Number" />
          <div>
            <button class="btn btn-primary mt-2" name="btnControlClk" id="btnControlClk" type="button" (click)="onClickSubmit();"> Submit </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
