import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from './environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AppConst {
  static appConfig: any;
  static apiUrl: string;
  static securityKey: string;
  static submissionSection: any = {};
  static rootWeb: string;
  static isPassTokenInUrl: boolean;
  static allPermissionOfUser: Array<any> = [];
  static allUserConfiguration: any = {};
  static MaxTIVValidationValue: any;
  static MaxTIVValidationMessage: string = "";
  static effectiveExpDateMsg: string = "";
  static floodWarningMsg: string = "";
  static EmpCountRateMsg: string = "";
  static InlandMarineDefault: Array<any> = [];
  // loadAppConfig() { 
  //   return AppConst.assignData(AppSetting);
  // }


  constructor(public http: HttpClient) {

  }

  loadAppSettings() {
    return this.http.get("assets/app-setting.json")
      .toPromise()
      .then((response: any) => {
        AppConst.assignData(response);
      });
  }

  public showItem(permissionName: string): boolean {
    if (AppConst.allPermissionOfUser && AppConst.allPermissionOfUser.filter(f => f === permissionName).length > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  static assignData(data: any) {
    
    AppConst.appConfig = data;
    AppConst.apiUrl = environment.production == true ? data.apiBaseUrlProd : data.apiBaseUrl;
    AppConst.securityKey = environment.production == true ? data.environment + data.securityKeyProd : data.environment +data.securityKey;
    AppConst.submissionSection = data.submissionSection;
    AppConst.isPassTokenInUrl = data.IsPassTokenInUrl;
    AppConst.rootWeb = environment.production == true ? data.rootWebProd : data.rootWeb;
    AppConst.effectiveExpDateMsg = data.effectiveExpDateMsg;
    AppConst.floodWarningMsg = data.floodWarningMsg;
    AppConst.MaxTIVValidationValue = data.MaxTIVValidationValue;
    AppConst.MaxTIVValidationMessage = data.MaxTIVValidationMessage;
    AppConst.EmpCountRateMsg = data.EmpCountRateMsg;
    AppConst.InlandMarineDefault = data.InlandMarineDefault;
    }

  }

