import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash'
import { AppConst } from '../../AppConst';
@Injectable({
  providedIn: 'root'
})
export class SubmissionService {

  http: HttpClient;
  constructor(@Inject(HttpClient) http: HttpClient) {
    this.http = http;
  }
  
  getSubmissionMaster(): Observable<any> {
    let url_ = `${AppConst.apiUrl}Submission/GetSubmissionMaster`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getMGUMaster(): Observable<any>{
    let url_ = `${AppConst.apiUrl}Submission/GetMguSyndicateLOBs`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  addUpdateSubmission(data): Observable<any> {
    var senddata = cloneDeep(data);
    var res = senddata;
    if (typeof res.ReceiptDate != 'string' && res.ReceiptDate)
      res.ReceiptDate = res.ReceiptDate.toLocaleString("en-US");
    if (typeof res.QuoteDto.EffectiveDate != 'string' && res.QuoteDto.EffectiveDate)
      res.QuoteDto.EffectiveDate = res.QuoteDto.EffectiveDate.toLocaleString("en-US");
    if (typeof res.QuoteDto.ExpirationDate != 'string' && res.QuoteDto.ExpirationDate)
      res.QuoteDto.ExpirationDate = res.QuoteDto.ExpirationDate.toLocaleString("en-US");
    if (typeof res.InsuredDto.DOB != 'string' && res.InsuredDto.DOB)
    res.InsuredDto.DOB = res.InsuredDto.DOB.toLocaleString("en-US");

    let url_ = `${AppConst.apiUrl}Submission/AddUpdateSubmission`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, res);  
  }
  GetExpDateTime(data): Observable<any> {
    var res = cloneDeep(data);
    if (typeof res.QuoteDto.EffectiveDate != 'string' && res.QuoteDto.EffectiveDate)
      res.QuoteDto.EffectiveDate = res.QuoteDto.EffectiveDate.toLocaleString("en-US");
    let url_ = `${AppConst.apiUrl}Submission/GetExpDateTime`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, res);
  }
  GetQuoteDetails(quoteId,controlNo): Observable<any> {
    let url_ = `${AppConst.apiUrl}Submission/GetQuoteDetails?quoteId=` + quoteId + "&controlNo=" + controlNo;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  } 
  getBrokerByAgency(UserId): Observable<any> {
    let url_ = `${AppConst.apiUrl}Submission/GetBrokerByAgency?userId=` + UserId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
  getQuoteId(controlNo): Observable<any> {
    let url_ = `${AppConst.apiUrl}Submission/GetQuoteId?controlNo=` + controlNo;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  } 
  copyQuoteAppln(CloneFromControlNo, QuoteId): Observable<any> {
    let url_ = `${AppConst.apiUrl}Submission/CopyQuoteAppln?cloneFromControlNo=` + CloneFromControlNo + "&quoteId=" + QuoteId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }
}
