import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { Guid } from 'guid-typescript';
import { AppConst } from '../../AppConst';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userData = new BehaviorSubject<any>({});
  http: HttpClient;
  constructor(@Inject(HttpClient) http: HttpClient, private router: Router
  ) {
    this.http = http;
  }

  logout() {
    localStorage.removeItem(`T${AppConst.securityKey}`);
    localStorage.removeItem(`CU${AppConst.securityKey}`);
    this.router.navigate(['logout']);
  }

  getUserRole(userId: Number): Observable<any> {
    let url_ = `${AppConst.apiUrl}User/GetUserDetailsByUserId?userId=` + userId;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, userId);
  }

  login(user): Observable<any> {
    let url_ = `${AppConst.apiUrl}User/AxonLogin`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, user);
  }

  setUserDetails() {
    if (!!JSON.parse(localStorage.getItem(`T${AppConst.securityKey}`))) {
      const userDetails = { userName: "", isLoggedIn: false, email: "" };
      const decodeUserDetails = JSON.parse(window.atob(JSON.stringify(JSON.parse(localStorage.getItem(`T${AppConst.securityKey}`))["AccessToken"]).split('.')[1]));
      userDetails.userName = decodeUserDetails.sub;
      userDetails.email = decodeUserDetails.email;
      userDetails.isLoggedIn = true;
      localStorage.setItem(`CU${AppConst.securityKey}`, JSON.stringify(userDetails));
      this.userData.next(userDetails);
    }
  }

  getTblUserDetailByGuid(guid:any) {
    let url_ = `${AppConst.apiUrl}User/GetTblUserByGuid?guid=` + guid;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  verifyUserDetails(userName: string, userPassword: string): Observable<any> {
    let url_ = `${AppConst.apiUrl}User/VerifyUserDetailsByUserName?userName=` + userName + '&userPass=' + userPassword;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, null);
  }

  getUserEncryptedGuid(userGuid: Guid): Observable<any> {
    let url_ = `${AppConst.apiUrl}User/GetUserEncryptedGuid?userGuid=` + userGuid;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.get<any>(url_);
  }

  getUserDecryptedGuid(userGuidEncryptInfo: any) {
    let url_ = `${AppConst.apiUrl}User/GetUserDecryptedGuid?userGuid`;
    url_ = url_.replace(/[?&]$/, "");
    return this.http.post<any>(url_, userGuidEncryptInfo);
  }

}
